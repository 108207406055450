import React from 'react'
import {useEffect,useState, useRef, memo} from 'react';
import { useForm} from 'react-hook-form';
import axios from 'axios';
import Images from "./image"
import useErrorHandler from './error/useErrorHandler'
import './popup.scss'
import {animateDivScroll,sanitTags/*,useEvent*//*,encodeDataToURL*//*,elementVisible*/} from './functions'
var classNames = require('classnames');

const Slider=memo((props)=>
{
const thumbScroll=useRef(null);
const [current,setCurrent]=useState(0)
//const a_img=(props.item.slider!=='undefined')?props.item.slider:[];
useEffect(() => {
if (thumbScroll.current===null) return
const scrollTo=thumbScroll.current.childNodes[(current===-1)?0:current].offsetLeft-thumbScroll.current.offsetLeft
//  if (props.current!=current) props.setCurrent(current);
  animateDivScroll(thumbScroll.current,scrollTo,1000)
}, [current]);
  /*
useEffect(() => {
window.setTimeout(function(){setCurrent(props.current)},100) // eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.current]);*/

  if (props.show===false || props.aimg.length===0) return ''
//prepare thumbnails
let thumbs=[]
  for (var i=0; i<props.aimg.length; i++)
   {
let temp=i
   thumbs.push(<img src={props.aimg[i].preview} alt="" key={"thumb_"+i} onClick={(e)=>{(e).stopPropagation();setCurrent(temp)}} className={classNames("image",{"image_act":(current===i)?true:false})} />)
   }
//
const scroller=(e)=>
{
let scrollTo;
e.preventDefault()
  if (e.deltaY>=-15 && e.deltaY<=15) scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*40);
  else scrollTo=thumbScroll.current.scrollLeft+(e.deltaY*5);
thumbScroll.current.scrollTo({
  left:scrollTo,
  behavior: "auto"
});
//animateDivScroll(thumbScroll.current,scrollTo,1000)
}



return(
<div className="popup_main popup_slider">
<div className="slider_container"><Images current={current} setcur={(e)=>{setCurrent(e)}} close={props.close} arr={props.aimg} arrows={true} swipe={true} click="once" /></div>
<div className="images" ref={thumbScroll} onWheel={(e)=>{scroller(e);}}>{thumbs}</div>
</div>
)
}, (prevProps, nextProps) => prevProps.current===nextProps.current)

/* eslint-disable no-useless-escape*/

const handlePhone = (event) =>
{
  const { key } = event;
  const isValidInput = /^\d*[\s()+\-]*\d*$/.test(key);
  if (!isValidInput && key !== '  ' && key!=='ArrowLeft' && key!=='ArrowRight' && key!=='Tab' && key!=='Enter' && key!=='Backspace') 
  {
  event.preventDefault();
   }
};

function Popup(props)
{
const setVisibleError = useErrorHandler()
const [loading, setLoading]=useState(false)
const [thankyou, setThankyou]=useState(false)
const [agree, setAgree]=useState(false)
//const [closePopup, setClosePopup]=useState(false)
const {register, handleSubmit,  formState: { errors},reset, setValue } = useForm();
const {item}=props
const [formOptions,setFormOptions]=useState({color:0,size:2})
const onSubmit=data=>sendMe(data)
const sendMe=(data)=>
{
if (loading) return
setLoading(true)
setThankyou('')
//data.comment='Выбран товар: '+sanitTags(item.title)+'\n'+data.comment /*\n*/
//data.comment=data.comment.replaceAll('\n',' ')
data.productName=sanitTags(item.title)
axios(
{
  method: 'post',
   headers: { 'Content-Type': 'application/json' },
  url: 'https://api.ru.protone.app/protone-store/verify-protone-coin',
  data: JSON.stringify(data)
})
.then(response=> { 

  if (response.data.isSuccess)
   {
setLoading(false)
setThankyou('Спасибо! Мы с вами свяжемся.')
reset()
window.setTimeout(function(){setThankyou(false)},10000)
//alert('Thank you')
   }
  else
   {
setThankyou(<span style={{color:"red"}}>{response.data.errorMessage}</span>)
//alert('Something bad. Information:'+response.data)
setLoading(false)
   }
//console.log(response);
})
.catch(function (error) {
setVisibleError('Не удается связаться с сервером',error)
//alert('Something bad. Information: '+error)
setLoading(false)
//console.log(error);
});
}
const close=(e)=>
{//return false
  if (e===true) 
   {
props.close()
/*
setClosePopup(true)
window.setTimeout(()=>{props.close()},300)
*/
   }
  else if (e.target.className==='popup_wrapper')
   {
props.close()
/*
setClosePopup(true)
window.setTimeout(()=>{props.close()},300)
*/
   }
}
const ItemColors=(props)=>
{
const {name,arr}=props
const rez=arr.map((item,i)=><div key={i} className={classNames("popup_option",{"popup_option_select":(formOptions[name]===i),"popup_option_disabled":!item.status})} onClick={()=>{setFormOptions({...formOptions,[name]:i});setValue(name, item.value);}} style={{backgroundColor:item.value}}></div>)
return(
<div className="popup_options">
<input type="hidden" name={name} value={arr[formOptions[name]].value} {...register(name, { required: true, maxLength: 255 })} />
{rez}
</div>
)
}
const ItemText=(props)=>
{
const {name,arr}=props
const rez=arr.map((item,i)=><div key={i} className={classNames("popup_option",{"popup_option_select":(formOptions[name]===i),"popup_option_disabled":!item.status})} onClick={()=>{setFormOptions({...formOptions,[name]:i});setValue(name, item.value);}}>{item.value}</div>)
return(
<div className="popup_options">
<input type="hidden" name={name} value={arr[formOptions[name]].value} {...register(name, { required: true, maxLength: 255 })} />
{rez}
</div>
)
}
const Options=({item})=>
{
 /*useEffect(() => { 
if (typeof item==="undefined")  return
  for (var i=0; i<item.length; i++)
   {
const n=item[i].name;
     if (typeof formOptions[n]==='undefined') setFormOptions({...formOptions,[n]:0})
    }
}// eslint-disable-next-line react-hooks/exhaustive-deps
,[]);*/
  if (typeof item==="undefined") return ''
var rez=[]; 
  for (var i=0; i<item.length; i++)
  {
    if (item[i].tip==='color')  rez.push(<div key={i}><div className="popup_options_title">{item[i].title}</div><ItemColors arr={item[i].arr} name={item[i].name} /></div>)
    if (item[i].tip==='text') rez.push(<div  key={i}><div className="popup_options_title">{item[i].title}</div><ItemText arr={item[i].arr} name={item[i].name} /></div>)
   }
return(
<div>
{rez}
</div>
)
}
const getTextValue=(item,real=false)=>
{
  switch (item)
   {
  case "comment":
    if (real && localStorage.getItem('comment_address')!==null)  return  localStorage.getItem('comment_address')
    if (!real && localStorage.getItem('comment')!==null)  return  localStorage.getItem('comment')
    break;
  default:
        if (localStorage.getItem(item)!==null) return  localStorage.getItem(item)
   }
}
const setTextValue=(item,value,real=false)=>
{
  switch (item) 
   {
  case "comment":
    if (real)  localStorage.setItem('comment_address',value)
    else localStorage.setItem('comment',value)
    break;
  default:
       localStorage.setItem(item,value)
   }
}
//console.log(errors.agree)
// lint don't like regexp
/* eslint-disable no-useless-escape*/
return(
<div className="store_popup">
<div className={classNames("popup_wrapper",{/*"close_popup":closePopup*/})} onClick={(e)=>close(e)}>
	<div  className={classNames("popup",{"popup_big":(typeof item.opt!=="undefined"),"popup_is_slider":(typeof item.slider!=="undefined")})}>
<img src="./i/close_popup.svg" alt="" className="popup_close" onClick={()=>close(true)} />
<div className="popup_count"><div className="wrapper"><img src="./i/popup_coin.png" alt="" title="Протоны" />{item.subtitle}</div><div className="big_counter">осталось {item.counter} штук</div></div>
<div className="popup_title" dangerouslySetInnerHTML={{__html: item.title}}></div>
<div className="popup_subtitle" dangerouslySetInnerHTML={{__html: item.bottomtitle+`<div class="small_counter">осталось ${item.counter} штук</div>`}}></div>
{(typeof item.slider==='undefined')?<div className="popup_main"><img src={"./i/store/big/"+item.bg} alt="" className="popup_main_img" onLoad={(e)=>e.target.style.opacity=1} /></div>:<Slider current={0} aimg={item.slider} item={item} close={()=>{return false}} />}
<div className="popup_form">
<form onSubmit={handleSubmit(onSubmit)}>
<Options item={item.opt} />
	<div className="title">Заполните форму</div>
	<input type="hidden" name="count" value={parseInt(item.subtitle.replaceAll(' ',''))} {...register("count", { required: true, maxLength: 255 })} />
<div className="div_impact_id"><input defaultValue={getTextValue("impactId")} disabled={loading} type="" name="impactId"  className={classNames("input_impact",{"input_error":errors.impactId})} placeholder="Импакт ID" {...register('impactId', { required: true, maxLength: 255 })}  onClick={(e)=>{e.target.select()}}  onChange={(e)=>setTextValue('impactId',e.target.value,item.real)}  /><div className="quest" onClick={()=>alert('“Импакт ID Вы можете найти в приложении Protone.app, перейдя в раздел “Настройки” и далее открыв вкладку “Профиль”')}  title="“Импакт ID Вы можете найти в приложении Protone.app, перейдя в раздел “Настройки” и далее открыв вкладку “Профиль”">?</div></div>
{/*<input defaultValue={getTextValue("phone")} disabled={loading} type="" name="phone" onKeyDown={handlePhone}  className={(errors.phone)?"input_error":""} placeholder="+7 (___) ___ ____" {...register('phone', { required: true, pattern: /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/im })}  onClick={(e)=>{e.target.select()}} onChange={(e)=>setTextValue('phone',e.target.value,item.real)} />*/}
<input defaultValue={getTextValue("telegramUsername")} disabled={loading} type="" name="telegramUsername"  className={(errors.telegramUsername)?"input_error":""} placeholder="Ник в Telegram" {...register('telegramUsername', { required: true,  maxLength: 255 })}  onClick={(e)=>{e.target.select()}} onChange={(e)=>setTextValue('telegramUsername',e.target.value,item.real)} />
	<textarea defaultValue={getTextValue("comment",item.real)} disabled={loading} name="comment" id="comment" className={(errors.comment)?"input_error":""} placeholder={(item.real)?"Адрес: Страна, город, улица, дом, кв., индекс":"Комментарий"} {...register('comment', { required: false, maxLength: 255 })}  onClick={(e)=>{e.target.select()}} onChange={(e)=>setTextValue('comment',e.target.value,item.real)} ></textarea>
	
<div><label className="wrapper_checkbox">
<div className="popup_check_text">Я принимаю условия <a href="https://protone.app/eula_protone.pdf" target="_blank" rel="noreferrer">пользовательского соглашения</a> и даю своё согласие на обработку моих персональных данных</div>
<input type="checkbox" name="agree" /*{...register('agree', { required: true})}*/ onChange={(e)=>setAgree(e.target.checked)}  /><span className="checkmark"></span>
</label></div>
	
	<div className={classNames("button",{"button_disabled":(loading || !agree)})}  onClick={handleSubmit(onSubmit)}>Отправить</div>
	<div className="hided_error">{thankyou}
{(errors.impactId)?<div style={{color:"red"}}>Поле Импакт ID не заполнено</div>:''}
{(errors.phone)?<div style={{color:"red"}}>Укажите мобильный телефон</div>:''}
{(errors.telegramUsername)?<div style={{color:"red"}}>Укажите свой ник в Telegram</div>:''}
{/*(errors.agree)?<div style={{color:"red"}}>Примите условия пользовательского соглашения</div>:''*/}
</div>
</form>
</div>
<div className="popup_botttom_text" dangerouslySetInnerHTML={{__html: item.bigtext}}></div>
	</div>
</div>
</div>
)
}

export default Popup;