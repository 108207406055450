import './App.scss';
import {useState,useEffect,memo} from 'react';
import {useEvent} from './functions.js'
import ErrorContainer from './error/ErrorContainer'
import Menu from "./menu"
import Popup from "./popup"
import {animateWindowScroll} from "./functions"
var classNames = require('classnames');

function App() 
{
const [popup,setPopup]=useState(false)

useEffect(() => {
  if (window.location.hash!=='') 
{
//исправляет баг некоторых браузеров по работе хеша и реакта
	window.setTimeout(()=>{
const cur=window.location.hash
window.location.hash=''
window.location.hash=cur
  },30)
}	
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);

useEffect(() => {
  if (popup!==false) document.body.classList.add("disable_scroll");
  else document.body.classList.remove("disable_scroll");
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[popup]);

//faq
const faq=[
{title:"Что такое Протоны?",
text:"Протоны - это внутриигровая валюта в приложении Protone.app, которая начисляется пользователям за выполнение миссий."},
{title:"Что такое Протон Стор (Protone Store)?",
text:"Protone Store — это наш внутриигровой магазин, в котором Вы можете обменять заработанные Протоны на реальные призы! Ознакомиться со списком призов и выбрать желанный Вы можете в разделе “Награды”."},
{title:"Как я могу получить Протоны?",
text:"<img src='./i/faq_how.jpg' alt='' /> Протоны Вы можете заработать, проходя импакт-миссии в приложении Protone App. Меняйте мир к лучшему, зарабатывайте Протоны и обменивайте их на призы из нашего магазина! Импакт-миссии можно найти в тематических локациях на карте и отфильтровать по вкладке “Импакт”. В каждой миссии указаны награды за прохождение."},
{title:"Где найти Импакт-ID?",
text:"<img src='./i/faq_impact.png' alt='' />Найти Импакт-ID можно в приложении в разделе «Настройки», далее необходимо перейти во вкладку «Профиль». Ваш импакт-ID находится под никнеймом и представляет собой сочетание букв и/или цифр."},
{title:"Как совершить обмен Протонов на товары из магазина?",
text:"Чтобы обменять Протоны, выберите приз из раздела “Награды”, нажмите кнопку “Обменять” и заполните все обязательные поля. Далее выберите  “Отправить”— после этого мы получим Вашу заявку на обмен, и как только обработаем ее, сразу напишем Вам в телеграмм для уточнения деталей и завершения обмена!"},
{title:"Сгорают ли Протоны, если не использовать их? ",
text:"Нет, Протоны не сгорают. Вы можете сразу потратить их на минимальный приз, или же оставить валюту на счету и копить на особые награды."},
{title:"Платное ли приложение Protone.app?",
text:"Приложение Protone App абсолютно бесплатно для наших пользователей!"},
{title:"Я могу подарить кому-то товары из магазина за свои Протоны?",
text:"Да, Вы можете приобрести приз из нашего магазина в качестве подарка для другого человека. В таком случае, сообщите об этом в графе «Комментарий»."},
{title:"Для доставки материальных призов нужны паспортные данные и ИНН. Зачем вам эта информация?",
text:"Паспортные данные нам необходимы исключительно для отчетности перед Федеральной Налоговой службой - иначе мы не сможем оформить передачу приза через бухгалтерию. Мы большая компания, и данные хранятся и обрабатываются соответствующим образом. Кроме того, спешим Вас успокоить: на данный момент на территории РФ невозможно совершить какие-либо подложные действия с Вашими документами без личного присутствия и согласия гражданина. Если вы не готовы поделиться указанными данными, Вы можете выбрать другой приз. Паспортные данные и ИНН мы запрашиваем только при выборе материального приза."},
{title:"Могу ли я оформить подписку Telegram-premium на аккаунт, на котором премиум подписка еще активна? ",
text:"Активировать подписку на аккаунте, на котором уже есть действующая подписка Telegram-premium, к сожалению, технически невозможно. В таком случае можете обменять Протоны, когда подписка закончится, или выбрать другую награду из нашего магазина."},
{title:"Платная ли доставка физических призов? ",
text:"Доставка осуществляется курьером по адресу и является бесплатной. "},
]
//for div
const cards=[
/*{title:"Бонусы<br />Вкусвилл",subtitle:"500",bottomtitle:"500 бонусов ВкусВилл<br />на любые покупки<br />1 бонус = 1 рубль ",bg:"vkusvill.png",big:false},*/

{title:"МНОГОРАЗОВАЯ<br />бутылка",subtitle:"2 000",disabled:true,bottomtitle:"Сокращай использование пластика стильно",bg:"butilka.png",big:false,counter:"1000",real:true,bigtext:"Эта стильная и экологичная бутылка изготовлена из высококачественной нержавеющей стали, которая не только долговечна, но и безопасна для окружающей среды – используя многоразовую емкость, вы снижаете количество пластиковых отходов. Нержавеющая сталь не впитывает запахи и не влияет на вкус напитков, а также безопасна для многократного использования. "},
/*{title:"сертификат<br />литрес",subtitle:"1 000",bottomtitle:"1000р<br />на любые книги",bg:"litres.png",big:false},*/
{title:"яндекс плюс",subtitle:"1 500",disabled:true,bottomtitle:"Подписка на <strong>3 месяца</strong> - музыка, фильмы, а также кэшбек баллами Яндекса и другие плюшки",bg:"yandex.png",big:false,counter:"1000",real:false,bigtext:"Откройте для себя полный спектр преимуществ, доступных только подписчикам Яндекс.Плюс – единая подписка объединяет множество сервисов, экономя ваши деньги и время.<br /><br />Яндекс.Плюс предоставляет доступ к трекам и подкастам на Яндекс.Музыке, просмотру фильмов и сериалов на Кинопоиске, а также позволяет накапливать и тратить баллы Плюса. Получить Яндекс.Плюс на 3 месяца бесплатно можно просто обменяв свои протоны на подписку."},
{title:"телеграм<br />премиум",disabled:true,subtitle:"2 750",bottomtitle:"Подписка на <strong>3 месяца</strong> - отсутствие рекламы, расшифровка войсов, больше лимиты, и многое другое",bg:"telegram.png",big:false,counter:"1000",real:false,bigtext:"Подписка Telegram Premium – это расширенные возможности и привилегии, доступные только для премиум-пользователей. Получить телеграм премиум бесплатно можно просто обменивая протоны на подписку.<br />С тг премиум вы получаете доступ к уникальным стикерам и анимациям, удвоенным лимитам, функции распознавания голосовых сообщений и многому другому! Вы можете получить все функции Telegram-premium сроком на 3 месяца всего за 2000 протонов."},
{title:"офигенное худи",subtitle:"9 000",bottomtitle:"Лимитный мерч <br />от наших дизайнеров",bg:"hudi.png",big:false,counter:"1000",real:true
,	opt:[
{name:'color',title:'Цвет',tip:"color",arr:[
{value:"#000",status:true},/*
{value:"#fff",status:true},
{value:"#c4c4c4",status:true},
{value:"#ff1414",status:true},
{value:"#117eff",status:true},
{value:"#60fd00",status:true},*/
]
},
{name:'size',title:'Размер',tip:"text",arr:[
{value:"XS",status:false},
{value:"S",status:false},
{value:"M",status:true},
{value:"L",status:true},
{value:"XL",status:true},
{value:"XXL",status:false},
]
},
		],slider:[
{"file": "./i/store/hudi/1.jpg","preview": "./i/store/hudi/1p.jpg"},/*
{"file": "./i/store/hudi/2.jpg","preview": "./i/store/hudi/2p.jpg"},
{"file": "./i/store/hudi/3.jpg","preview": "./i/store/hudi/3p.jpg"},
{"file": "./i/store/hudi/4.jpg","preview": "./i/store/hudi/4p.jpg"},
{"file": "./i/store/hudi/5.jpg","preview": "./i/store/hudi/5p.jpg"},
{"file": "./i/store/hudi/1.jpg","preview": "./i/store/hudi/1p.jpg"},
{"file": "./i/store/hudi/2.jpg","preview": "./i/store/hudi/2p.jpg"},
{"file": "./i/store/hudi/3.jpg","preview": "./i/store/hudi/3p.jpg"},
{"file": "./i/store/hudi/4.jpg","preview": "./i/store/hudi/4p.jpg"},
{"file": "./i/store/hudi/5.jpg","preview": "./i/store/hudi/5p.jpg"},
{"file": "./i/store/hudi/1.jpg","preview": "./i/store/hudi/1p.jpg"},
{"file": "./i/store/hudi/2.jpg","preview": "./i/store/hudi/2p.jpg"},
{"file": "./i/store/hudi/3.jpg","preview": "./i/store/hudi/3p.jpg"},
{"file": "./i/store/hudi/4.jpg","preview": "./i/store/hudi/4p.jpg"},
{"file": "./i/store/hudi/5.jpg","preview": "./i/store/hudi/5p.jpg"},*/
	],
bigtext:"Наше лимитированное худи с вышивкой – идеальное сочетание стиля и комфорта. Дизайн этого дропа разработан нашими дизайнерами ограниченной партией.<br /><br />Оверсайз крой и мягкий капюшон обеспечивают максимальный комфорт, позволяют свободно двигаться и чувствовать себя уютно в любых условиях. В кенгуру-кармане спереди можно хранить мелочи или согреть руки в непогоду. <br /><br />Худи изготовлено из 80% хлопка и 20% полиэстера, что гарантирует долговечность, мягкость и легкость в уходе, а вышитый рисунок никогда не сотрется."
},
{title:"эко-стеллаж<br />для зелени",subtitle:"15 000",bottomtitle:"Собственная сити-ферма для выращивания зелени и овощей Просто добавь воды!",bg:"stel.png",big:false,counter:"1000",real:true,bigtext:"Футуристичный и функциональный стеллаж фитолампами для рассады и микрозелени с фитолампами “sunlike” спектра. Удобная конструкция позволяет собирать и разбирать стеллаж всего за 10-15 минут. <br /><br />Стеллаж изготовлен из перерабатываемого ABS пластика, каждая полка выдерживает до 70 кг, а блоки питания надежно защищены от влаги. Три съемных фитосветильника мощностью по 20W обеспечивают яркое и равномерное освещение. Создайте свою экоферму прямо у себя дома и наслаждайтесь цветущим садом круглый год!"},
{title:"Эйрподсы",subtitle:"42 000",bottomtitle:"Минимум шума,<br />максимум стиля",bg:"vip_airpods.png",big:false,counter:"1000",real:true,bigtext:"Знаменитые беспроводные наушники, ставшие классикой. Легкие, стильные и функциональные. Они идеально подходят для активного образа жизни и обеспечивают высокое качество звука. Получить оригинальные AirPods бесплатно можно обменяв свои накопленные в приложении протоны."},
//{title:"Билет на сигнал",subtitle:"12 000",bottomtitle:"Билет на главную тусовку лета:<br />15-19 августа, Никола-Ленивец",bg:"signal.png",big:false,counter:"1000",real:false,bigtext:"Билет на ежегодный фестиваль Signal 2024, который пройдет в Никола-Ленивце с 15 по 19 августа. 8 сцен, 4 дня, более 200 артистов! Билет дает доступ на территорию фестиваля на 1 человека на все 4 дня. Повторный вход по браслету без ограничений. Проживание/трансфер/парковка - в билет не входят."},
]
/*
const cards_vip=[
{title:"VIP Билет на сигнал",subtitle:"25 000",bottomtitle:"VIP доступ,<br />бэкстейдж на сцены,<br />бесплатный шаттл",bg:"vip_signal.png",big:true,counter:"1000",real:false,bigtext:"Все то же, только лучше! Вип-билет дает доступ на бэкстейдж сцен Signal, Möbius, Prizma, а также бесплатный шаттл по территории. Билет дает доступ на территорию фестиваля на 1 человека на все 4 дня. Повторный вход по браслету без ограничений. Проживание/трансфер/парковка - в билет не входят."},
{title:"Эйрподсы",subtitle:"42 000",bottomtitle:"Минимум шума,<br />максимум стиля",bg:"vip_airpods.png",big:true,counter:"1000",real:true,bigtext:"Знаменитые беспроводные наушники, ставшие классикой. Легкие, стильные и функциональные. Они идеально подходят для активного образа жизни и обеспечивают высокое качество звука. Получить оригинальные AirPods бесплатно можно обменяв свои накопленные в приложении протоны."},
]
*/
useEffect(() => {
//preload cards
let preload=[]
  for (var i=0; i<cards.length; i++) 
   {
let item=new Image();
item.src='./i/store/'+cards[i].bg;
preload.push(item)
   }
//end of preload	
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
  return (
    <div className="App">
<Menu />
<div name="main" aria-label="main"  aria-hidden="true" />
<Head />
<div name="store" aria-label="store" aria-hidden="true" />
  <Store cards={cards} /*cards_vip={cards_vip}*/ setPopup={setPopup}   />
<div name="faq" aria-label="faq" aria-hidden="true" />
<Faq faq={faq} />
<Footer />
<ErrorContainer>
{(popup!==false)?<Popup close={()=>setPopup(false)} item={popup} />:""}
</ErrorContainer>
    </div>
  );
}

const Head=()=>
{
const utm=(window.location.search!=='')?'&'+window.location.search.substr(1,999):''
const goStore=()=>
{
const top=document.getElementById('store').getBoundingClientRect().top+window.pageYOffset-80
animateWindowScroll(top, 500,true)
}
return (
<div className="head_block" id="main">
{/*<div className="head_center_logo"><img src="./i/header_logo.svg" alt="" /></div>*/}
<div className="head_logo_mobile"></div>
	<div className="head_left">
		<div className="head_left_one">
<img src="./i/qr.png" alt="" className="head_qr" />
<div className="head_text"><span>Protone</span> — приложение,<br/ >в котором пользователи получают реальные награды за то, что прокачивают себя и мир вокруг.</div>
		</div>
		<div className="head_left_two">
<a href={"https://redirect.appmetrica.yandex.com/serve/749267348866796478?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&ios_ifa={IOSIFA}&ios_ifa_sha1={IDFA_LC_SH1}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer" className="head_button"><img src="./i/apple.svg" alt="" className="apple" /></a>
<a href={"https://redirect.appmetrica.yandex.com/serve/677209770347726262?click_id={LOGID}&google_aid={GOOGLEAID}&android_id={ANDROIDID}&android_id_sha1={ANDROID_ID_LC_SH1}&search_term={keyword}&google_aid_sha1={GOOGLE_AID_LC_SH1}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer" className="head_button"><img src="./i/google.svg" alt="" className="google" /></a>
		</div>
	</div>
	<div className="head_right">
		<img src="./i/header_right.png" alt="" className="head_right_img" />
		<div className="head_right_button" onClick={()=>goStore()}>К магазину</div>
	</div>
</div>
)
}

	const StoreCard=(props)=>
	{
const bg=props.bg
const bigsize=props.bigsize
const setPopup=props.setPopup
const styleBg=(bigsize===true && props.big===true)?{}:{backgroundImage:'url("./i/store/'+bg+'"), url("./i/store_bg_small.svg"), linear-gradient(149deg, rgba(137, 168, 190, 0.1) 0%, rgba(93, 107, 184, 0.1) 100%)'};
const bigImage=(bigsize===true && props.big===true)?<div><img src={"./i/store/"+bg} className="store_card_img" alt="" /></div>:''
return(<div className={classNames("store_card_wrapper",{"store_card_wrapper_big":(props.big && bigsize===true)})} onClick={()=>{if (bigsize===true && props.disabled!==true ) setPopup(props.card);}}>
<div className={classNames("store_card",{"store_card_big":(props.big && bigsize===true)})} style={styleBg}>
{bigImage}
	<div className="store_card_wrapper_top">
<div className="title" dangerouslySetInnerHTML={{__html: props.title}}></div>
	</div>
		<div className="title_bottom_wrapper">
	<div className="subtitle"><img src="./i/store_coin.png" alt="" title="Протоны" />{(props.disabled)?'sold out':props.subtitle}</div>
	<div className="title_bottom" dangerouslySetInnerHTML={{__html: props.bottomtitle}}></div>
	<div className={classNames("button",{"button_sold_out":props.disabled})} onClick={()=>{if (props.disabled!==true) setPopup(props.card)}}>Обменять</div>
		</div>
</div>
</div>
)
	}

	const StoreCards=memo(({cards,vip,setPopup,bigsize})=>
		{
return(
<div className={classNames("store_cards",{"store_cards_big":vip})} >
{cards.map((item,i)=><StoreCard key={i} title={item.title} subtitle={item.subtitle} bottomtitle={item.bottomtitle} big={item.big} bg={item.bg} card={item} setPopup={setPopup} bigsize={bigsize} disabled={item.disabled}  />)}
</div>
)
		}, (prevProps, nextProps) => prevProps.bigsize === nextProps.bigsize)

const Store=(props)=>
{
const {cards,/*cards_vip,*/setPopup}=props
const [bigsize,setBigSize]=useState(false);
const i_resize=(e)=>
{
window.setTimeout(function(){
setBigSize(window.innerWidth>760?true:false)
},10)
}
//add handler for resize
useEvent('resize', i_resize)
useEvent('load', i_resize)
useEffect(() => { 
i_resize() }// eslint-disable-next-line react-hooks/exhaustive-deps
,[]);
if (cards==='undefined') return ''


return(
<div id="store">
<div className="store_first_title">
	<div className="column_1">
		<img src={bigsize?"./i/store_logo.svg":"./i/store_coin.png"} alt="" className="store_logo1" />
		<div className="big_title">PROTONE<br />STORE</div>
	</div>
		<div className="column_2">
			<div className="store_text">Здесь Протоны, полученные за выполнение миссий в приложении <span>Protone.app</span>, можно обменять на крутые награды
			</div>
		<img src="./i/store_coin.png" alt="" className="store_logo2" />
		</div>
</div>
<StoreCards cards={cards} vip={false} bigsize={bigsize} setPopup={setPopup} />
{/*
<div className="store_second_title big_title">Особые награды</div>
<StoreCards cards={cards_vip} vip={true} bigsize={bigsize} setPopup={setPopup} />
*/}
{/*<div className="store_bottom_text">Что такое Протоны?<br /><br />Протоны - это внутриигровая валюта в приложении Protone.app, которая начисляется пользователям за выполнение миссий.</div>*/}
</div>
)
}

const FaqItem=(props)=>
{
const [open, setOpen]=useState(false)
useEffect(() => { //init 
 if (props.id===0) setOpen(true)
   } // eslint-disable-next-line react-hooks/exhaustive-deps
,[]); /*
const scroll=(e)=>
{
const comp=(window.innerWidth>991)?110:80 //110 80
const top=e.target.getBoundingClientRect().top+window.pageYOffset-comp
  if (window.innerWidth<600) animateWindowScroll(top, 500,true)
}*/
return (
	<div className={classNames("faq_item","no_tap",{"faq_item_show":open})} onClick={(e)=>{setOpen(!open);/*scroll(e);*/}}>
<div className="faq_title no_tap"><div className="faq_title_text no_tap">{props.title}</div><div className="faq_title_state no_tap"><img alt="" className={classNames("faq_img",{"faq_img_open":open})}  src="./i/faq_arrow.svg" /></div></div>
<div className={classNames("faq_answer","no_tap",{"faq_answer_show":open})}  dangerouslySetInnerHTML={{__html: props.text}}></div>
			</div>
)
}

const Faq=(props)=>
{

const arr=props.faq.map((item,i) => <FaqItem title={item.title} text={item.text} id={i} key={i} />);
return (
<div className="faq">
	<div className="big_title faq_top_title"  id="faq">FAQ</div>
		<div className="faq_wrapper">
	<div className="faq_block">
{arr}
<a href="https://t.me/Protone_Store_Bot" target="_blank" rel="noreferrer" className="faq_button">Другой вопрос...</a>
	</div>
		</div>

</div>
)
}
	
const Footer=()=>
{
const utm=(window.location.search!=='')?'&'+window.location.search.substr(1,999):''
return(
<div className="footer">
	<div className="footer_column footer_column1">
		<img src="./i/logo.svg" alt="" className="footer_logo" />
		<a href="https://t.me/Protone_Store_Bot" target="_blank"  rel="noreferrer" className="footer_button"><img src="./i/teleg_button.svg" alt="" />Поддержка Protone.App</a>
		<a href="https://t.me/Protone_Store_Bot" target="_blank"  rel="noreferrer" className="footer_button"><img src="./i/teleg_button.svg" alt="" />Чат-бот магазина</a>
	</div>
	<div className="footer_column footer_column2">
<img src="./i/qr.png" alt="" className="footer_qr" />
	</div>
	<div className="footer_column footer_column3">
			<div className="footer_download_text">Скачать Protone.app</div>
		<div>
<a href={"https://redirect.appmetrica.yandex.com/serve/749267348866796478?click_id={LOGID}&search_term={keyword}&campaign_id={campaign_id}&ios_ifa={IOSIFA}&ios_ifa_sha1={IDFA_LC_SH1}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer" className="footer_button_download"><img src="./i/apple.svg" alt="" className="apple" /></a>
<a href={"https://redirect.appmetrica.yandex.com/serve/677209770347726262?click_id={LOGID}&google_aid={GOOGLEAID}&android_id={ANDROIDID}&android_id_sha1={ANDROID_ID_LC_SH1}&search_term={keyword}&google_aid_sha1={GOOGLE_AID_LC_SH1}&campaign_id={campaign_id}&device_type={device_type}&region_name={region_name}&source_type={source_type}&source={source}&position_type={position_type}&phrase_id={phrase_id}"+utm} target="_blank" rel="noreferrer" className="footer_button_download"><img src="./i/google.svg" alt="" className="google" /></a>
		</div>
	</div>
	<div className="footer_column footer_column4">
<div className="social_text">Социальные сети</div>
		<div className="footer_social_wrapper">
<a href="https://t.me/protone" target="_blank" rel="noreferrer"><img src="./i/footer_teleg.svg" alt="" className="social_img" /></a>
<a href="https://www.tiktok.com/@protone.app" target="_blank" rel="noreferrer"><img src="./i/footer_tiktok.png" alt="" className="social_img" /></a>
<a href="https://www.vk.com/protone_app" target="_blank" rel="noreferrer"><img src="./i/footer_vk.svg" alt="" className="social_img" /></a>	
		</div>
	</div>
</div>
)
}

export default App;
