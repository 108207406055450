/*
Module slider by ALaKO 2023-2024
swipe:true|false --add support of touch
timer:int|false --add auto-rotate slides, not worked properly
arrows:true|false --add support of arrow left\right to rotate slides
click=twice|once --add support of change slides by mouse (1\2 to prev\next or only next)
cursor=true|false --add support of cursor arrows (recommend with click=twice)
*/
import React from 'react'
import {useState, useEffect, useRef, memo} from 'react'
//import {useDebounce} from './debounce'
import './image.css'
var  timer, preloadLeft=new Image(), preloadRight=new Image()


function useEvent(event,handler,passive=false)
{
useEffect(()=>
  {
window.addEventListener(event, handler, passive)
    return function cleanup()
    {
window.removeEventListener(event, handler)
    }
  })
}
	
function Images(props)
{
let timerA1,timerA2;
const ImagesPath = ''
const myRef=useRef(null);
const [ignoreCurrent,setIgnoreCurrent]=useState(false)
const [currentImage,setCurrentImage]=useState(-1)
const [curImg,setCurImg]=useState('')
const [ImgCls,setImgCls]=useState('current_image')
const [prevImg,setPrevImg]=useState('')
const [prevImgCls,setPrevImgCls]=useState('hide_image')
const isMobile=(/iphone/.test(navigator.userAgent.toLowerCase())) || (/android/.test(navigator.userAgent.toLowerCase())) // || (/ipad/.test(ua))
const supportTouch=(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0))
//add custom cursor
const customCursor=(e)=>
{
  if (props.cursor===true)
(e.nativeEvent.clientX/window.innerWidth>=0.5)?myRef.current.className="gallery cursorRight":myRef.current.className="gallery cursorLeft"
}
const customClick=(e)=>
{
(e).stopPropagation()
  if ((isMobile || supportTouch)) return; //disable click for mobile
/*1/2 of screen is left slide, 1/2 is right slide */
  if (props.click==="twice") animateImage((e.nativeEvent.clientX/window.innerWidth>=0.5)?true:false)
/* click at slide = next slide*/
  if (props.click==="once") animateImage(false)
}
//end add custom cursor
//add support of touch
const [touchStart,setTouchStart]=useState('')
const [touchEnd,setTouchEnd]=useState('')
const htouchStart=(e)=>{setTouchStart(e.targetTouches[0].clientX)}
const htouchMove=(e)=>{setTouchEnd(e.targetTouches[0].clientX)}
const htouchEnd=(e)=>
{  
if (props.swipe===false ) return false
  if (Math.abs(touchStart-touchEnd)>50 && touchEnd!==0) 
{
/*clearTimer()
setStopTimer(false)*/
e.stopPropagation()
  if (touchStart-touchEnd>50) animateImage(false)
  else if (touchStart-touchEnd<-50) animateImage(true)
setTouchEnd(0)
setTouchStart(0)
   }
}
//end touch

const checkKey=(e)=> //for left-right navigation by arrow 
{
  if (props.arrows!==true) return false
var event = window.event ? window.event : e
const key=event.keyCode;
  if (key===37 || key===39)
   {
/*clearTimer()
setStopTimer(true)*/
   }
  if (key===37) animateImage(true) //next image when arrow right
  if (key===39) animateImage(false) //prev image when arrow left
  if (key===27) props.close() //close when escape
  if (key===32) animateImage(true) //next image when space
}

const returnNextImg=(left=false)=> //return next, or prev image index
{
return (left?(currentImage+props.arr.length-1)%props.arr.length:(currentImage+1)%props.arr.length)
}

const preload=()=> //preload next and prev images
{
preloadLeft=new Image()
preloadRight=new Image()
preloadLeft.src=ImagesPath+props.arr[returnNextImg(true)].file
preloadRight.src=ImagesPath+props.arr[returnNextImg(false)].file
}

const openImage=(i)=> //open image and start preloading
{
i=parseInt(i)
  if (parseInt(currentImage)!==i) 
   {
setCurImg(ImagesPath+props.arr[i].file)
    setCurrentImage(i)	
    }
preload()
}

const setTimer=()=>
{
  if (typeof props.timer==='undefined' || props.timer===false) return false;
window.clearInterval(timer)
timer=window.setInterval(()=>{animateImage(false)},props.timer)	
}

const animateImage=(left=false)=>
{
  if (props.arr.length<=1) return
setTimer() //clear timer
setIgnoreCurrent(true)
window.clearTimeout(timerA1)
window.clearTimeout(timerA2)
 timerA2=window.setTimeout(function(){setIgnoreCurrent(false);},50)
  if (isMobile)
   {
setPrevImgCls('hide_image disableAnimation');
setImgCls('current_image disableAnimation')
   }
setPrevImg(ImagesPath+props.arr[currentImage].file)
openImage(returnNextImg(left))
  if (isMobile) timerA1=window.setTimeout(function(){setImgCls('next_image');},20)
  else timerA1=window.setTimeout(function(){setImgCls('next_image');setPrevImgCls('prev_image')},1)
}
 useEvent('keydown', checkKey)
 	 
//init slider
useEffect(() => {
//console.log(currentImage,props.current,ignoreCurrent)
  /*if (currentImage!==props.current || props.current===0) 
{*/
  if (ignoreCurrent) 
  {
setIgnoreCurrent(false)
   }
   else
   { 
   	   
//setImgCls('current_image')
setImgCls('current_image disableAnimation')
setPrevImgCls('hide_image disableAnimation');
//setPrevImg('')
window.setTimeout(()=>{
openImage(props.current)
setImgCls('next_image')
setTimer()},1)
}
//}
//end init slider
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [props.current]);

useEffect(() => {
//setIgnoreCurrent(true)
props.setcur(currentImage)// eslint-disable-next-line react-hooks/exhaustive-deps
}, [currentImage]);
const OneImage=memo((props)=>
{
const [loaded,setLoaded]=useState(false)
useEffect(()=>
{//console.log(props)
setLoaded(false)
  },[props.src,props])
const load=(e)=>
{ 
setLoaded(true)
  }
return(
<img src={props.src} style={{opacity:(loaded)?1:0}} className="one_image" onLoad={load} alt="" />
)
},[props.src])
const MyImage=(props)=>
{
return(
<div className={props.cls}><OneImage src={props.src} /></div>	
)
}
//react �������� �������� ��� ontouch ��������, �� memo, �� ���-�� ������ �������� �� ������. �������� ���
const render=(isMobile)?<div><div style={{backgroundImage:"url('"+curImg+"')"}} className={ImgCls} ></div>
<div style={{backgroundImage:"url('"+prevImg+"')"}}  className={prevImgCls} ></div></div>
:<div><MyImage src={curImg} cls={ImgCls} cur={currentImage}  /><MyImage src={prevImg} cls={prevImgCls} cur={currentImage}  /></div>
return (
<div>
<div className="gallery" ref={myRef}   onClick={(e)=>customClick(e)} onMouseMove={(e)=>customCursor(e)} onTouchStart={htouchStart} onTouchEnd={htouchEnd} onTouchMove={htouchMove}>
{render}
</div>
</div>
)
}


export default Images;